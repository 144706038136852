import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { logout, showLoginModal } from "../actions/Auth";
import { setCurrentDashboard } from "../actions/Dashboard";
import SvgIcon from "../components/SvgIcon";
import { withRouter } from 'react-router'


class FooterContainer extends Component {

	constructor ( props ) {
        super( props ); 
        this.clickFooterMenu = this.clickFooterMenu.bind(this);
    }

	// Gets the current year
	getCurrentYear(){
		const theDate = new Date();
		return theDate.getFullYear()
	}

	clickFooterMenu(event){ 
		// eslint-disable-next-line
		this.props.setCurrentDashboard( parseInt( event.target.dataset.dashboardId ) );
		if( this.props.location.pathname !== '/'){
			this.props.history.push('/');
		}
	}

	// Render the footer structure
	render() {

		//console.log(this.props.availableDashboards);
		let dashboardMenu = null;

		if( this.props.availableDashboards.length ){
			dashboardMenu = 
			<ul className="footer-menu">
				{ 
					this.props.availableDashboards.map( ( item, index ) => {
						return <li key={`dashboard-menu-${index}`} className="menu-item"><Link className="btn-link" data-dashboard-id={item.id} onClick={this.clickFooterMenu} to="/" >{item.name}</Link></li>
					})
				}
            </ul>
		}

		/*
		<li className="menu-item">
			<Link 
				className={`menu-item`} 
				to="/site-info/policies"
			>
				Policies
			</Link>
		</li>

		<li className="menu-item"><a href="https://trca.ca/">Glossary</a></li>
		
		*/


		return (
			<footer className="footer" role="contentinfo">

			    <div className="container-fluid wrapper">

			        <div className="row">

			            <div className="contact col-xs-12 col-md-3">

			            	<div className="footer-section">
				                <h3 className="footer-section-heading">Contact Us</h3>
			     
								<div className="contact-section mailing">
								    <h4 className="contact-heading">Administrative Office Building:</h4>
								    <a href="http://maps.google.com/?q=5+Shoreham+Drive+Toronto%2C+ON+M3N+1S4" target="_blank" rel="noopener noreferrer">
								        <span className="address-line1">5 Shoreham Drive</span>
								        <span className="address-line2">Toronto, ON M3N&nbsp;1S4</span>
								    </a>
								</div>

								<div className="contact-section phone">
								    Phone: <a href="tel:416.661.6600">416.661.6600</a>
								 </div>
								 <div className="contact-section phone">
								    Fax: 416.661.6898
								 </div>
								 <div className="contact-section phone">
								    Email: <a href="mailto:info@trca.ca"> info@trca.ca</a>
								</div>
							</div>

				            { dashboardMenu !== null && 
					            <div className="footer-section dashboard-menu-mobile">
					            	<h3 className="footer-section-heading">Dashboard</h3>
				       				{dashboardMenu}
					            </div>
					        }

			            </div>


			            <div className="footer-section footer-section-center col-md-push-1 col-md-8 ">
			            	<div className="row">

			            		<div className="footer-xl-left col-sm-12 col-xl-6" role="navigation" aria-labelledby="footer-nav">

				            		{ dashboardMenu !== null && 
							            <div className="footer-section dashboard-menu-desktop">
							            	<h3 className="footer-section-heading">Dashboard</h3>
						       				{dashboardMenu}
							            </div>
							        }
			
									<ul id="footer-nav" className="footer-section footer-menu footer-menu-left">
										<li className="menu-item">
											<Link 
												className={`menu-item`} 
												to="/camera-views"
											>
												Camera Views
											</Link>
											
										</li>
				                    	<li className="menu-item">
											<Link 
												className={`menu-item`} 
												to="/site-info/terms-conditions"
											>
												Terms & Conditions
											</Link>
											
										</li>
				                    	<li className="menu-item">
				                    		<Link 
												className={`menu-item`} 
												to="/faq"
												>
												FAQ
											</Link>
				                    	</li>
				                    	
				                    	<li className="menu-item">
											<Link 
												className={`menu-item`} 
												to="/site-info/about"
											>
												About
											</Link>
										</li>
										
				                    	{ this.props.auth.isLoggedIn ? (
											<div>
												<li className="menu-item">
													<button className="btn-link" onClick={this.props.logout}>Logout</button>
												</li>
												<li className="menu-item">
													<Link 
														className={`menu-item`} 
														to="/edit-profile"
													>
														Edit Profile
													</Link>
												</li>
											</div>
										) : (
											<li className="menu-item">
												<button className="btn-link" onClick={this.props.showLoginModal}>Login</button>
											</li>
										)}
									 </ul>
			
			            	
			            			<ul className="footer-section footer-menu footer-menu-right">
				                    	<li className="menu-item"><a href="https://trca.ca/">TRCA.CA</a></li>
				                    	<li className="menu-item"><a href="https://trca.ca/flood/">TRCA Flood Risk Management</a></li>
				                    	<li className="menu-item"><a href="https://www.canada.ca/en/campaign/flood-ready.html">Flood Ready</a></li>
										<li className="menu-item"><a href="https://trca.ca/privacy-protocol/">Privacy Protocol</a></li>
									 </ul>
				            	
				            		<div className="footer-section footer-social">
				            			<a href="https://twitter.com/TRCA_Flood">
				            				<span className="screen-reader-text">Follow us on Twitter</span>
				            				<SvgIcon icon="twitter" />
				            			</a> 
				            		</div>
				            		<div className="top-copy">
				            			<p className="footer-section">Copyright © { this.getCurrentYear() }, Toronto and Region Conservation</p>
				            		</div>
				            	</div>

					            <div className="footer-xl-right footer-section-right col-sm-12">
					            	<div className="footer-section">
					            		<p>TRCA's flood monitoring network measures in-stream water levels and precipitation at stations throughout the GTA. While designed as a portal for TRCA Flood Staff, limited access to the website and data is available to the general public. </p>
					            	</div>
					            	<div className="footer-section">	            	
			            				<h4 className="footer-subheading">To report a flooding incident from a watercourse, please leave a message on TRCA's floodline at <a href="tel:4166616514">416.661.6514</a>.</h4>
			            				<p>If you are in danger, call <strong>911</strong> immediately.</p>
			            			</div>
			            			<div className="footer-section">
			            				<p>In the event of basement flooding please contact your local municipality.</p>
			            			</div>
					            </div>

					            <div className="col-sm-12  bottom-copy">
			            			<p className="footer-section">Copyright © { this.getCurrentYear() }, Toronto and Region Conservation</p>
			            		</div>
			            	</div>
			            </div>
			        </div>
			    </div>
			 </footer>
			
		)

	}
}


function mapStateToProps(state) {
	return { 
        auth: state.auth,
        availableDashboards: state.dashboard.availableDashboards
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		logout: logout,
		showLoginModal : showLoginModal,
		setCurrentDashboard: setCurrentDashboard,
    }, dispatch)    
}


export default withRouter( connect( mapStateToProps, matchDispatchToProps )( FooterContainer )); 


